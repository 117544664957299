import React from "react";

function Hero() {
  return (
    <div className="hero">
      <div className="hero-content">
        <h1>Welcome to Our Living Museum</h1>
        <p>
          "Welcome to the official event page for the 5th Grade Living Museum
          Project!"
        </p>
        <div className="hero-sub">
          <h2>Jeremiah Strzelczyk</h2>
        </div>
      </div>
      <div className="scroll-indicator">
        <span className="arrow-down"></span>
      </div>
    </div>
  );
}

export default Hero;
