import React from "react";

const References = () => {
  return (
    <section className="reference-section">
      <div
        style={{
          fontFamily: "Arial, sans-serif",
          lineHeight: "1.5",
          textAlign: "left",
        }}
      >
        <h1 className="reference-h1">References</h1>
        <div className="reference-div">
          {/* BEGIN SINGLE REFERENCES */}
          <p className="reference-p">
            Google Photos. (n.d.).{" "}
            <em>Securely back up and share your photos.</em>{" "}
            <a
              href="https://www.google.com/intl/en_us/photos/about/"
              target="_blank"
              rel="noopener noreferrer"
              className="reference-a"
            >
              https://www.google.com/intl/en_us/photos/about/
            </a>
          </p>

          <p className="reference-p">
            Google Workspace. (n.d.).{" "}
            <em>Google Slides: Online presentations for teams.</em>{" "}
            <a
              href="https://workspace.google.com/products/slides/"
              target="_blank"
              rel="noopener noreferrer"
              className="reference-a"
            >
              https://workspace.google.com/products/slides/
            </a>
          </p>

          <p className="reference-p">
            Office of Superintendent of Public Instruction (OSPI). (n.d.).{" "}
            <em>Learning standards and instructional materials.</em>{" "}
            <a
              href="https://ospi.k12.wa.us/student-success/learning-standards-instructional-materials"
              target="_blank"
              rel="noopener noreferrer"
              className="reference-a"
            >
              https://ospi.k12.wa.us/student-success/learning-standards-instructional-materials
            </a>
          </p>

          <p className="reference-p">
            ParentSquare. (n.d.). <em>Connecting schools and families.</em>{" "}
            <a
              href="https://www.parentsquare.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="reference-a"
            >
              https://www.parentsquare.com/
            </a>
          </p>

          <p className="reference-p">
            PBS NewsHour Classroom. (2023, April 3).{" "}
            <em>
              Be MediaWise lesson 5: How to determine the credibility of a
              source on social media. PBS
            </em>
            .{" "}
            <a
              href="https://www.pbs.org/newshour/classroom/lesson-plans/2023/04/lesson-plan-how-lateral-reading-helps-debunk-sensational-claims"
              target="_blank"
              rel="noopener noreferrer"
              className="reference-a"
            >
              https://www.renaissance.com/2021/05/20/blog-the-importance-of-using-diagnostic-assessment-4-tips-for-identifying-learner-needs/
            </a>
          </p>

          <p className="reference-p">
            SWGfL. (n.d.). <em>Why is online safety important?</em>{" "}
            <a
              href="https://swgfl.org.uk/online-safety/why-is-online-safety-important/"
              target="_blank"
              rel="noopener noreferrer"
              className="reference-a"
            >
              https://poorvucenter.yale.edu/Formative-Summative-Assessments
            </a>
          </p>

          <p className="reference-p">
            Zoom Video Communications. (n.d.).{" "}
            <em>Video conferencing, cloud phone, webinars, chat, and more.</em>{" "}
            <a
              href="https://www.zoom.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="reference-a"
            >
              https://www.zoom.com/
            </a>
          </p>
          {/* END SINGLE REFERENCES */}
        </div>
      </div>
    </section>
  );
};

export default References;

// import React from "react";

// const References = () => {
//   return (
//     <section className="reference-section">
//       <div
//         style={{
//           fontFamily: "Arial, sans-serif",
//           lineHeight: "1.5",
//           textAlign: "left",
//         }}
//       >
//         <h1 className="reference-h1">References</h1>
//         <div className="reference-div">
//           {/* BEGIN SINGLE REFERENCES */}
//           <p className="reference-p">
//             PBS NewsHour Classroom.(2023, April 3).{" "}
//             <em>
//               Be MediaWise lesson 5: How to determine the credibility of a
//               source on social media. PBS
//             </em>
//             .{" "}
//             <a
//               href="https://www.pbs.org/newshour/classroom/lesson-plans/2023/04/lesson-plan-how-lateral-reading-helps-debunk-sensational-claims"
//               target="_blank"
//               rel="noopener noreferrer"
//               className="reference-a"
//             >
//               https://www.renaissance.com/2021/05/20/blog-the-importance-of-using-diagnostic-assessment-4-tips-for-identifying-learner-needs/
//             </a>
//           </p>

//           <p className="reference-p">
//             SWGfL. (n.d.)<em>Why is online safety important?</em>
//             <a
//               href="https://swgfl.org.uk/online-safety/why-is-online-safety-important/"
//               target="_blank"
//               rel="noopener noreferrer"
//               className="reference-a"
//             >
//               https://poorvucenter.yale.edu/Formative-Summative-Assessments
//             </a>
//           </p>

//           {/* END SINGLE REFERENCES */}
//         </div>
//       </div>
//     </section>
//   );
// };

// export default References;
